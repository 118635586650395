import { DuplicateIcon, ExternalLinkIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import { useWeb3Context } from '../hooks/useWeb3Context';
import { NFT } from '../services/constants';
import { truncateAddress } from '../services/utils';

interface PendingBuyNftProps {
  imageModal: string;
  nftPrice: number;
  tokenId: string;
  nftTotal: number;
  explorerURL: string;
  account: string;
  nftAddress: string;
}

const PendingBuyNft = ({
  account,
  imageModal,
  nftPrice,
  tokenId,
  nftTotal,
  nftAddress,
  explorerURL
}: PendingBuyNftProps) => {
  const { network } = useWeb3Context();
  const [copied, setCopied] = useState<boolean>(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(NFT);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <>
      <div className="w-full md:w-[392px] container z-40 buy-nft-modal p-16">
        <div className="flex flex-col justify-center items-center">
          <div className="modal-detail-title flex w-full items-center mb-12">
            <div className="flex items-center">
              <p className="rounded-full px-16 py-8 bg-pill text-white font-base">{network}</p>
              <div className="ml-8">
                <a className="cursor-pointer flex items-center mr-7" href="#">
                  <p className="text-white font-base text-16">{truncateAddress(account)}</p>
                  <DuplicateIcon onClick={copyToClipboard} className="h-18 w-18 text-white" aria-hidden="true" />
                </a>
                {copied ? <span className="text-white">copied!</span> : <></>}
              </div>
            </div>
          </div>

          <div className="modal-detail-content flex justify-between w-full flex-col">
            <div>
              <div className="nft-image relative">
                <img className="w-full h-auto  rounded-2xl" src={imageModal} width="360" height="360" />
                <div className="nft-bar">
                  <p className="bar-text">
                    {' '}
                    #{tokenId} / {nftTotal}
                  </p>
                </div>
              </div>
              <h3 className="text-center font-bold text-white font-base mt-16 mb-4">{nftPrice} mADA</h3>
            </div>
            <div className="buy-nft-modal-select w-full mt-16">
              <div className="flex flex-col w-full justify-between items-center px-24 py-18">
                <h3 className="modal-subtitle mb-10">Pending...</h3>
                <div className="mb-10 loader">
                  <img className="w-[34px] h-[34px]" src="images/icon-loading.png" width="34" height="34" />
                </div>
                <a className="cursor-pointer flex items-center" href={explorerURL} target="_blank">
                  <p className="text-primary-color font-base text-14 mr-4 underline">Go to Explorer</p>
                  <ExternalLinkIcon className="h-16 w-16 text-primary-color" aria-hidden="true" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingBuyNft;
