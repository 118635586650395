import * as React from 'react';
import PendingBuyNft from '../components/PendingBuyNft';

const PendingBuyNftPage = ({ location }) => {
  const props = location.state;
  return (
    <>
      <PendingBuyNft {...props}></PendingBuyNft>
    </>
  );
};

export default PendingBuyNftPage;

// const {
//   account,
//   imageModal,
//   nftPrice,
//   tokenId,
//   nftTotal,
//   nftAddress,
//   explorerURL,
// } = location.state;
